<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <!--    <img-->
    <!--      alt="Your Company"-->
    <!--      class="mx-auto h-12 w-auto"-->
    <!--      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"-->
    <!--    />-->
    <h2
      class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
    >
      {{ 'auth.signInComponent.signInToYourAccount' | translate }}
    </h2>

    @if (favoriteOrganizations.length > 1) {
      <select
        [formControl]="organizationFormControl"
        autocomplete="country-name"
        class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        id="country"
        name="country"
      >
        @for (fav of favoriteOrganizations; track fav) {
          <option [value]="fav">{{ fav }}</option>
        }
      </select>
    }
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form
        (ngSubmit)="onSubmit()"
        [formGroup]="signInForm"
        action="#"
        class="space-y-6"
        method="POST"
      >
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            {{ 'auth.signInComponent.emailAddress' | translate }}
          </label>
          <div class="mt-1">
            <input
              autocomplete="email"
              class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              formControlName="email"
              id="email"
              name="email"
              required
              type="email"
            />
          </div>
          @if (signInForm.controls.email.errors?.['emailRequiredForReset']) {
            <p class="mt-2 text-sm text-red-600" id="email-error">
              {{ 'auth.signInComponent.emailRequiredForReset' | translate }}
            </p>
          }
        </div>

        <div>
          <label for="password" class="block text-sm font-medium text-gray-700">
            {{ 'auth.signInComponent.password' | translate }}
          </label>
          <div class="mt-1">
            <input
              autocomplete="current-password"
              class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              formControlName="password"
              id="password"
              minlength="8"
              name="password"
              required
              type="password"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label for="remember-me" class="ml-2 block text-sm text-gray-900">
              {{ 'auth.signInComponent.rememberMe' | translate }}
            </label>
          </div>

          <div class="text-sm">
            <a
              (click)="forgotPassword()"
              class="font-medium text-indigo-600 hover:text-indigo-500"
              routerLink=""
            >
              {{ 'auth.signInComponent.forgotYourPassword' | translate }}
            </a>
          </div>
        </div>

        <div>
          <button
            [disabled]="!signInForm.valid"
            class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:opacity-50 focus:ring-offset-2"
            type="submit"
          >
            {{ 'auth.signInComponent.signIn' | translate }}
          </button>
        </div>
      </form>
      @if (googleProviderEnabled$ | async) {
        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="bg-white px-2 text-gray-500">
                {{ 'auth.signInComponent.orContinueWith' | translate }}
              </span>
            </div>
          </div>
          <div class="mt-6">
            <div>
              <a
                (click)="signInWithGoogle()"
                [routerLink]=""
                class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
              >
                <span class="sr-only">
                  {{ 'auth.signInComponent.signInWithGoogle' | translate }}
                </span>
                <svg
                  height="20px"
                  viewBox="0 0 20 20"
                  width="20px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="surface1">
                    <path
                      d="M 19.988281 10.1875 C 19.988281 9.367188 19.921875 8.769531 19.773438 8.148438 L 10.199219 8.148438 L 10.199219 11.847656 L 15.820312 11.847656 C 15.707031 12.765625 15.09375 14.152344 13.734375 15.082031 L 13.714844 15.207031 L 16.742188 17.496094 L 16.953125 17.515625 C 18.878906 15.777344 19.988281 13.222656 19.988281 10.1875 "
                      style="
                        stroke: none;
                        fill-rule: nonzero;
                        fill: rgb(25.882353%, 52.156863%, 95.686275%);
                        fill-opacity: 1;
                      "
                    />
                    <path
                      d="M 10.199219 19.929688 C 12.953125 19.929688 15.265625 19.046875 16.953125 17.515625 L 13.734375 15.082031 C 12.875 15.667969 11.71875 16.078125 10.199219 16.078125 C 7.503906 16.078125 5.214844 14.339844 4.398438 11.9375 L 4.277344 11.945312 L 1.128906 14.328125 L 1.089844 14.4375 C 2.765625 17.695312 6.210938 19.929688 10.199219 19.929688 "
                      style="
                        stroke: none;
                        fill-rule: nonzero;
                        fill: rgb(20.392157%, 65.882353%, 32.54902%);
                        fill-opacity: 1;
                      "
                    />
                    <path
                      d="M 4.398438 11.9375 C 4.183594 11.316406 4.058594 10.652344 4.058594 9.964844 C 4.058594 9.277344 4.183594 8.613281 4.386719 7.996094 L 4.378906 7.863281 L 1.191406 5.445312 L 1.089844 5.492188 C 0.398438 6.84375 0 8.359375 0 9.964844 C 0 11.570312 0.398438 13.089844 1.089844 14.4375 L 4.398438 11.9375 "
                      style="
                        stroke: none;
                        fill-rule: nonzero;
                        fill: rgb(98.431373%, 73.72549%, 1.960784%);
                        fill-opacity: 1;
                      "
                    />
                    <path
                      d="M 10.199219 3.851562 C 12.113281 3.851562 13.40625 4.660156 14.140625 5.335938 L 17.019531 2.589844 C 15.253906 0.984375 12.953125 0 10.199219 0 C 6.210938 0 2.765625 2.238281 1.089844 5.492188 L 4.386719 7.996094 C 5.214844 5.59375 7.503906 3.851562 10.199219 3.851562 "
                      style="
                        stroke: none;
                        fill-rule: nonzero;
                        fill: rgb(92.156863%, 26.27451%, 20.784314%);
                        fill-opacity: 1;
                      "
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      }
      <div class="mt-6">
        <div class="flex justify-end">
          <div class="text-sm">
            <a
              (click)="register()"
              class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
            >
              {{ 'auth.signInComponent.dontHaveAnAccount' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer
  class="hidden md:fixed bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6"
>
  <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
    © 2023
    <a href="https://freddy.ovh/" class="hover:underline">Freddy™</a>
    . All Rights Reserved. 5d5e18fa - v{{ appVersion }}
  </span>
</footer>
